
import {defineComponent,} from "vue";
import List from "@/components/operations/transfers/companies/List.vue";




export default defineComponent({
  name: "operation-transfer-firmalari",
  components: {
    List,
  },

});
