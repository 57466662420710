
import {computed, defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import store from "@/store";
import TransferCompanyAdd from "@/components/operations/transfers/companies/AddNew.vue";
import {Actions} from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";



export default defineComponent({
    name: "operation-transfer-company",
    components: {
        TransferCompanyAdd,
    },

    setup() {

        store.dispatch(Actions.TRANSFER_COMPANY_LIST, {});
        const myTransferCompany = computed(() => {
            return store.getters.transferCompanyList;
        })

        const deleteCompany = (ID) => {
            store.dispatch(Actions.TRANSFER_COMPANY_LIST, {});
            Swal.fire({
                title: 'Emin misiniz?',
                text: "Kaydi silmek işlemi geri alınamaz.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: 'Kaydı Sil',
                cancelButtonText: `Vazgeç`,
                confirmButtonColor: '#9c1d1d',
                cancelButtonColor: '#3085d6',
            }).then(function (result) {
                if (result.isConfirmed) {
                    const payload1 = {
                        ID: ID,
                    }
                    store.dispatch(Actions.TRANSFER_COMPANY_DELETE, payload1)
                        .then(() => {
                            Swal.fire(
                                'Silindi!',
                                'Kayit silme işlemi tamamlandı.',
                                'success'
                            )
                        }).catch(() => {
                        Swal.fire({
                            text: store.getters.getTransferCompanyErrors[0],
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: "Tekrar dene!",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-danger",
                            },
                        });
                    });

                }
            });
        };


        onMounted(() => {
            setCurrentPageBreadcrumbs("Transfer Firmaları", ["Operasyon"]);
        });


        return {
            myTransferCompany,
            deleteCompany,

        };
    },
});
