
import {computed, defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import {ErrorMessage, Field, Form} from "vee-validate";

interface addCompany {
    companyTitle: string;
    companyShortTitle: string;
    companyPhone: string;
    companyEmail: string;
    companyWeb: string;

}

export default defineComponent({
    name: "operation-transfer-company-add",
    components: {
        Field,
        Form,
        ErrorMessage,
    },


    props: {
        companyID: String,
    },

    setup(props) {

        onMounted(() => {
            setCurrentPageBreadcrumbs("Transfer Firmaları", ["Operasyon"]);
        });
        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const newRoomModalRef = ref<null | HTMLElement>(null);

        const addCompany = ref<addCompany>({
            companyTitle: "",
            companyShortTitle: "",
            companyPhone: "",
            companyEmail: "",
            companyWeb: "",
        });

        const validationSchema = Yup.object().shape({
            companyTitle: Yup.string().required("Doldurulması zorunlu"),
            companyShortTitle: Yup.string().required("Doldurulması zorunlu"),
            companyPhone: Yup.string().required("Doldurulması zorunlu"),
            companyEmail: Yup.string().required("Doldurulması zorunlu"),
            companyWeb: Yup.string().required("Doldurulması zorunlu"),
        });

        const submit = () => {
            if (!submitButtonRef.value) {
                return;
            }


            const payload1 = {
                Title: addCompany.value.companyTitle,
                ShortTitle: addCompany.value.companyShortTitle,
                Phone: addCompany.value.companyPhone,
                Email: addCompany.value.companyEmail,
                WebSite: addCompany.value.companyWeb,
            }


            store.dispatch(Actions.TRANSFER_COMPANY_INSERT, payload1)
                .then(() => {
                    store.dispatch(Actions.TRANSFER_COMPANY_LIST, {});
                    Swal.fire({
                        text: "Tebrikler. Başarılı bir şekilde ekleme işlemini tamamladınız.",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Geri Dön!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-primary",
                        },
                    }).then(function () {
                        submitButtonRef.value.disabled = false;
                        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
                        hideModal(newRoomModalRef.value);
                        // Go to page after successfully login
                        //router.push({name: "userlist"});
                    });
                }).catch(() => {
                    Swal.fire({
                        text: store.getters.getUserErrors[0],
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Tekrar dene!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-danger",
                        },
                });
                submitButtonRef.value.disabled = false;
                submitButtonRef.value.setAttribute("data-kt-indicator", "off");
            });

        }

        return {
            submitButtonRef,
            addCompany,
            newRoomModalRef,
            validationSchema,
            submit,

        };
    },
});
